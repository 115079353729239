import graphClient from '@/api/graph';
import getStudentInfoById from '@/api/graphql/queries/getStudentInfoById';
import {StudentEnrollment, StudentInfo} from '@/api/types';
import {simulateCompletedEnrollments} from '@/utils/enrollmentUtils';
import {parseDegreeInfo} from '@/degrees/audit/utils';
import {summarizeStats} from '@/utils/degreeUtils';
import {RequirementBlock} from '@/degrees/types';
import {getRequirementBlock} from '@/api/graphql/queries/getRequirementBlock';
import auditBlockRule from '@/degrees/audit/auditBlockRule';
import {AuditedRequirementBlock, StudentInfoWithDegree} from '@/degrees/audit/types';
import _ from 'lodash';
import auditRequirementBlock from '@/degrees/audit/auditRequirementBlock';
import {searchStudentInfo} from '@/api/graphql/queries/searchStudentInfo';
import {BlockType} from '@/degrees/common';
import {parseLatestAcademicProgramFromStudentInfo} from '@/api/transformers';
import getTransferCourses from '@/api/getTransferCourses';


export interface AuditStudentResponse {
    studentInfo: StudentInfoWithDegree;
    auditedBlock: AuditedRequirementBlock | null;
    errors: string[];
}
export async function auditStudent(studentId: string): Promise<AuditStudentResponse> {
    const results = await graphClient.query({
        query: getStudentInfoById,
        variables: {
            studentId
        }
    });

    let errors: string[] = [];

    let studentInfo = results.data.items[0];

    if (!studentInfo) {
        console.warn('student was not found in ps_names. proceeding to search all tables anyways...');
        studentInfo = await searchStudentInfo(studentId);
    }

    if (studentInfo) {
        const simulatedInfo: StudentInfo = {
            ...studentInfo,
            enrollments: simulateCompletedEnrollments(studentInfo.enrollments),
        };

        const withDegreeInfo = parseDegreeInfo(simulatedInfo);
        const enrollmentSummary = summarizeStats(simulatedInfo.enrollments);

        const studentInfoWithDegree: StudentInfoWithDegree = {
            ...simulatedInfo,
            ...withDegreeInfo,
            ...enrollmentSummary
        };

        console.log('studentInfoWithDegree.enrollments: ', studentInfoWithDegree.enrollments);


        // === fetching transfer courses. this should mutate existing courses with additional transfer props if applicable ===
        const destinationInstitution = withDegreeInfo.institution;

        const sendingEnrollments: StudentEnrollment[] = simulatedInfo.enrollments.filter(o => {
            return (o.institution !== destinationInstitution);
        });
        const sendingCourseIds = sendingEnrollments.map(o => o.class.courseId);

        const transferredCourses = await getTransferCourses(sendingCourseIds, destinationInstitution);

        console.log('transferredCourses: ', transferredCourses);

        if (sendingEnrollments.length !== transferredCourses.length) {
            console.warn('sendingEnrollments.length !== transferredCourses.length');
        }

        // iterate through enrollments because getTransferCourses
        sendingEnrollments.forEach(o => {

        });

        transferredCourses.forEach(transferredCourse => {
            const enrollment = sendingEnrollments.find(o => {
                return o.class.courseId === transferredCourse.sendingCourseId;
            });
            if (!enrollment) {
                console.error('Unable to locate sending enrollment');
            } else {
                const t = {
                    courseId: transferredCourse.courseId,
                    subject: transferredCourse.subject,
                    courseNumber: transferredCourse.courseNumber,
                };
                if (enrollment.transferredAs) {
                    enrollment.transferredAs.push(t);
                } else {
                    enrollment.transferredAs = [ t ];
                }
            }
        });
        // ============

        console.log('auditStudent props: ', studentInfoWithDegree);

        const onlyEarnedCreditNoLabs = _.filter(simulatedInfo.enrollments, o => {
            return o.class.courseComponent !== 'LAB' &&
                o.earnCredit === 'Y';
        });

        let auditedBlock: AuditedRequirementBlock | null = null;

        if (studentInfoWithDegree.degree) {
            const degreeBlockQueryProps = {
                institution: studentInfoWithDegree.institution,
                blockType: 'DEGREE' as BlockType,
                blockValue: studentInfoWithDegree.degree
            };
            console.log('degreeBlockQueryProps: ', degreeBlockQueryProps);

            let block: RequirementBlock = await getRequirementBlock(degreeBlockQueryProps);

            console.log('auditStudent degree block: ', block);

            if (!block) {
                console.warn('Did not find degree block using institution from academic plan. Attempting to look up institution in latest academic program instead...');
                const program = parseLatestAcademicProgramFromStudentInfo(studentInfo);
                if (program) {
                    studentInfoWithDegree.institution = program.institution;
                }
                block = await getRequirementBlock({
                    ...degreeBlockQueryProps,
                    institution: program?.institution || ''
                });
            }

            if (!block) {
                throw new Error(`Unable to find degree block: ${degreeBlockQueryProps.blockValue} from ${degreeBlockQueryProps.institution}`);
            }

            const devBlock = _.cloneDeep(block);
            // devBlock.parseTree.body_list = devBlock.parseTree.body_list.slice(7, 8);
            // devBlock.parseTree.body_list = [
            //     {"class_credit": {"label": {"label_str": "General Elective", "label_tag": "10"}, "is_hidden": false, "is_pseudo": false, "conjunction": null, "course_list": {"list_type": "OR", "institution": "BMC01", "except_courses": [ [ "ENG__hidden__", "100.5__hidden__", null ], [ "ENG__hidden__", "101__hidden__", null ], [ "ENG__hidden__", "201__hidden__", null ] ], "requirement_id": "RA000144", "include_courses": [], "scribed_courses": [ [ [ "@", "@", null ] ] ]}, "max_classes": null, "max_credits": 4, "min_classes": null, "min_credits": 4, "allow_classes": null, "allow_credits": null}}
            // ];
            // devBlock.parseTree.header_list = [];

            auditedBlock = await auditRequirementBlock(devBlock, {
                ...studentInfoWithDegree,
                enrollments: onlyEarnedCreditNoLabs
            });
        } else {

            errors.push('No academic plans were found for this student.');
        }

        return {
            studentInfo: studentInfoWithDegree,
            auditedBlock,
            errors
        };

    } else {
        throw new Error('Unable to find studentId: ' + studentId);
    }
}
