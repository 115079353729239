import {
    TreeId,
    SimulationData,
    StageData,
    TreeData,
    TreeStatus,
} from '@/store/simulation/types';
import { AvailableTimesSelection } from '@/components/preferences/AllAvailableTimesPicker';
import {
    CourseClass,
    CourseGroup,
    SectionMode, SelectedCourse, SelectedCourseExtract
} from '@/api/types';
import { formatISO, getHours, getMinutes, parseISO } from 'date-fns';
import { convertDayToInt, parseMeetingDay } from '@/api/transformers';
import _ from 'lodash';
import { ClassSearchCriteriaValues } from '@/components/class-search/ClassSearchForm';
import { SimulationResults } from '@/api/simulation/SimulationAPI';

export const isCohortAllowed = (cohort = ''): boolean => {
    if (!cohort || cohort === '3') return false;
    return true;
};

const initStageData = (stageId: string, unavailableClass: SelectedCourse): StageData => {
    const stage: StageData = {
        replacementMissingCorequisites: false,
        id: stageId,
        treeId: parseInt(stageId[1]) as TreeId,
        completed: false,
        completedTs: '',
        enteredTs: formatISO(new Date()),
        unavailableClass,
        initialResponse: null,
        replacementResponse: null,
        reasonResponse: null,
        negativeExpectations: null,
        additionalChangesResponse: null,
        resolvedCart: null
    };
    return stage;
};

export const initTreeData = (treeId: TreeId, originalCart: SelectedCourse[]): TreeData => {
    let qualifyingMode: SectionMode;
    switch (treeId) {
        case 1:
            qualifyingMode = 'OA';
            break;
        case 2:
            qualifyingMode = 'OS';
            break;
        case 3:
            qualifyingMode = 'P';
            break;
    }

    const qualifyingCourses = _.filter(originalCart, o => {
        return o.sectionMode === qualifyingMode;
    });

    let status: TreeStatus = 'Pending';
    let originalUnavailableClass: SelectedCourse | null = null;
    const unavailableClasses: SelectedCourse[] = [];

    if (qualifyingCourses.length) {
        originalUnavailableClass = _.sample(qualifyingCourses) as SelectedCourse;
        unavailableClasses.push(originalUnavailableClass);

    } else {
        status = 'Skipped';
    }

    return {
        id: treeId,
        completedStages: [],
        originalCourse: originalUnavailableClass,
        originalReason: null,
        startedTs: '',
        status,
        unavailableClasses,
    };
};

export const initSimulationData = (originalCart: SelectedCourse[], props: Partial<SimulationData> = {}): SimulationData => {
    const data: SimulationData = {
        startedTs: '',
        availableTimes: [],
        originalSubmittedCart: _.cloneDeep(originalCart),
        tree1: initTreeData(1, originalCart),
        tree2: initTreeData(2, originalCart),
        tree3: initTreeData(3, originalCart),
        completedTs: '',
        // initializing this later below
        currentStage: null,
        ...props,
    };

    console.log('data: ', data);
    // Don't allow more than 2 trees
    if (data.tree1.status === 'Pending' && data.tree2.status === 'Pending') {
        data.tree3.status = 'Skipped';
    }

    // Determine the first stage student will encounter
    let tree: TreeData;
    if (data.tree1.status === 'Pending') {
        tree = data.tree1;
    } else if (data.tree2.status === 'Pending') {
        tree = data.tree2;
    } else if (data.tree3.status === 'Pending') {
        tree = data.tree3;
    }

    const stageId = `t${tree!.id}r1`;
    const stage: StageData = initStageData(stageId, tree!.originalCourse!);
    data.currentStage = stage;

    return data;
};

export const getCurrentTree = (data: SimulationData): TreeData | null => {
    if (!data || !data.startedTs || data.completedTs) return null;

    if (data.currentStage) {
        switch (data.currentStage.treeId) {
            case 1:
                // if (data.tree1.status !== 'Pending') {
                //     console.error('The tree for current stage is supposed to have a Pending status');
                //     return null;
                // }
                return data.tree1;
            case 2:
                // if (data.tree2.status !== 'Pending') {
                //     console.error('The tree for current stage is supposed to have a Pending status');
                //     return null;
                // }
                return data.tree2;
            case 3:
                // if (data.tree3.status !== 'Pending') {
                //     console.error('The tree for current stage is supposed to have a Pending status');
                //     return null;
                // }
                return data.tree3;
        }
    } else {
        console.warn('data.currentStage is null, which means there is no currentTree');
        return null;
    }
};

const getVariableModesByTree = (treeId: TreeId): [ SectionMode, SectionMode ] => {
    if (treeId === 1) throw new Error('Only tree 2 or 3 allowed');

    const mainST = treeId === 2 ? 'OS' : 'P';
    const otherST = treeId === 2 ? 'P' : 'OS';
    return [ mainST, otherST ];
};

export type getNextTreeStageResult = {
    reconciledTrees: TreeData[];
    nextTreeStage: StageData | null;
};
// This assumes we are calling this during the simulation in progress. Otherwise an error will be thrown.
export const getNextTreeStage = (data: SimulationData, currentCart: SelectedCourse[]): getNextTreeStageResult => {
    if (!data.currentStage) throw new Error('no active stage');

    const { originalSubmittedCart } = data;

    const originalClassesStillPresent = originalSubmittedCart.filter((originalItem) => {
        const present = currentCart.find((currentItem) => originalItem.courseId === currentItem.courseId);
        return !!present;
    });

    const reconciledTrees: TreeData[] = [];
    let nextTree: TreeData | null = null;

    if (data.currentStage.treeId === 1) {
        nextTree = initTreeData(2, originalClassesStillPresent);
        reconciledTrees.push({...nextTree});

        if (nextTree.status === 'Skipped') {
            nextTree = initTreeData(3, originalClassesStillPresent);
            reconciledTrees.push({...nextTree});
        }

    } else if (data.currentStage.treeId === 2) {
        nextTree = initTreeData(3, originalClassesStillPresent);
        reconciledTrees.push({...nextTree});
    }

    console.log('getNextTreeStage nextTree: ', nextTree);

    if (nextTree?.status === 'Skipped') {
        return {
            reconciledTrees,
            nextTreeStage: null
        };
    }

    if (nextTree) {
        const stageId = `t${nextTree.id}r1`;
        return {
            reconciledTrees,
            nextTreeStage: initStageData(stageId, nextTree.originalCourse!)
        };
    } else {
        return {
            reconciledTrees,
            nextTreeStage: null
        };
    }
};

export const getNextStage = (data: SimulationData): StageData | null => {
    if (!data.currentStage) throw new Error('no active stage');

    const { currentStage } = data;

    if (!currentStage.replacementResponse) {
        throw new Error('Stage not completed. Missing replacement response.');
    }

    if (currentStage.replacementResponse.replacementClass === 'NoReplacement') {
        // TODO: go to next tree. perhaps getNextStage should focus only within the tree. And just return complete
        // TODO: then have a separate function dedicated to moving to next tree (and marking the old one as complete)
        return null;
    }

    const tree = getCurrentTree(data)!;
    const { originalCourse } = tree;
    const { replacementResponse: { replacementClass } } = currentStage;
    let mainST, otherST;
    switch (currentStage.id) {
        case 't1r1':
            // is replacement async?
            if (replacementClass.sectionMode === 'OA') {
                return initStageData('t1r4', replacementClass);
            } else {
                // if no, then is it with same course?
                if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                    // if yes, is it during available time?
                    for (const section of replacementClass.selectedSections) {
                        if (isClassDuringAvailableTimes(section, data.availableTimes)) {
                            // if yes, go to t1r2
                            return initStageData('t1r2', replacementClass);
                        }
                    }
                    // if no, go to t2
                    // return parseT2R1(data);
                    return null;
                } else {
                    // if no, then go to t2
                    // return parseT2R1(data);
                    return null;
                }
            }
        case 't1r2':
            if (replacementClass.sectionMode === 'OA') {
                return initStageData('t1r4', replacementClass);
            } else {
                if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                    return initStageData('t1r3', replacementClass);
                } else {
                    return null;
                }
            }
        case 't1r3':
            if (replacementClass.sectionMode === 'OA') {
                return initStageData('t1r4', replacementClass);
            } else {
                // we already know replacement is not with the same course, because in this stage the original course
                // no longer has any sections available
                return null;
            }
        case 't1r4':
            return null;

        case 't2r1':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                if (replacementClass.sectionMode === otherST) {
                    return initStageData('t2r3', replacementClass);
                } else {
                    return initStageData('t2r2', replacementClass);
                }
            } else {
                if (replacementClass.sectionMode === mainST) {
                    return initStageData('t2r4', replacementClass);
                } else {
                    return null;
                }
            }

        case 't2r2':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            if (replacementClass.sectionMode === mainST) {
                return initStageData('t2r4', replacementClass);
            } else {
                if (replacementClass.sectionMode === otherST) {
                    if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                        return initStageData('t2r3', replacementClass);
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }

        case 't2r3':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            if (replacementClass.sectionMode === mainST) {
                return initStageData('t2r4', replacementClass);
            } else {
                if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                    return initStageData('t2r2', replacementClass);
                } else {
                    return null;
                }
            }

        case 't2r4':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            return null;

        case 't3r1':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                if (replacementClass.sectionMode === otherST) {
                    return initStageData('t3r3', replacementClass);
                } else {
                    return initStageData('t3r2', replacementClass);
                }
            } else {
                if (replacementClass.sectionMode === mainST) {
                    return initStageData('t3r4', replacementClass);
                } else {
                    return null;
                }
            }

        case 't3r2':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            if (replacementClass.sectionMode === mainST) {
                return initStageData('t3r4', replacementClass);
            } else {
                if (replacementClass.sectionMode === otherST) {
                    if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                        return initStageData('t3r3', replacementClass);
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }

        case 't3r3':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            if (replacementClass.sectionMode === mainST) {
                return initStageData('t3r4', replacementClass);
            } else {
                if (replacementClass.courseNumber === originalCourse?.courseNumber) {
                    return initStageData('t3r2', replacementClass);
                } else {
                    return null;
                }
            }

        case 't3r4':
            [ mainST, otherST ] = getVariableModesByTree(tree.id);
            return null;
        default:
            throw new Error('invalid stage id');
    }
};

// A random Monday used to calculate which day the availableTimesSelection is in.
// The react-available-times library returns the number of minutes after Monday midnight.
// So 5am Monday would be 300 minutes.
export const WEEK_START = parseISO('2020-11-16T00:00:00Z');
export const isClassDuringAvailableTimes = (courseClass: Pick<CourseClass, 'meetings'>, availableTimes: AvailableTimesSelection[]): boolean => {
    // first find out which days the class takes place on
    for (let i = 0; i < courseClass.meetings.length; i++) {
        const meeting = courseClass.meetings[i];

        const meetingStart = parseISO(meeting.startTime);
        const meetingEnd = parseISO(meeting.endTime);

        const meetingDay = parseMeetingDay(meeting);
        const meetingDayValue = convertDayToInt(meetingDay!);

        // how many minutes passed Monday midnight
        const meetingStartInMinutesOffset = ((meetingDayValue - 1) * 1440) + (getHours(meetingStart) * 60) + getMinutes(meetingStart);
        const meetingEndInMinutesOffset = ((meetingDayValue - 1) * 1440) + (getHours(meetingEnd) * 60) + getMinutes(meetingEnd);

        for (let j = 0; j < availableTimes.length; j++) {
            if ((meetingStartInMinutesOffset > availableTimes[j].start && meetingStartInMinutesOffset < availableTimes[j].end) ||
                (meetingEndInMinutesOffset > availableTimes[j].start && meetingEndInMinutesOffset < availableTimes[j].end)
            ) {
                console.warn('class is during available time: ', courseClass);
                return true;
            } else {
                continue;
            }
        }
    }
    return false;
};

// https://stackoverflow.com/a/67717721
export function mergeContinuousTimes(times: AvailableTimesSelection[]): AvailableTimesSelection[] {
    const sorted = _.orderBy<AvailableTimesSelection>(times, [ 'start' ], [ 'asc' ]);

    const ret = sorted.reduce((acc, curr) => {
        // Skip the first range
        if (acc.length === 0) {
            return [ curr ];
        }

        const prev: AvailableTimesSelection = acc.pop()!;

        if (curr.end <= prev.end) {
            // Current range is completely inside previous
            return [ ...acc, prev ];
        }

        // Merges overlapping (<) and contiguous (==) ranges
        if (curr.start <= prev.end) {
            // Current range overlaps previous
            return [ ...acc, { start: prev.start, end: _.max([ curr.end, prev.end ])! } ];
        }

        // Ranges do not overlap
        return [ ...acc, prev, curr ];
    }, [] as AvailableTimesSelection[]);

    return ret;
}


export const parseEncounteredStages = (data: SimulationData): StageData[] => {
    const currentTree = getCurrentTree(data);
    if (currentTree) {
        const stages: StageData[] = [ ...currentTree.completedStages ];
        if (data.currentStage) {
            stages.push(data.currentStage);
        }
        return stages;
    } else {
        return [];
    }
};

// All classes that were specifically chosen by simulation to be unavailable across ALL trees.
export const parseUnavailableClasses = (data: SimulationData): SelectedCourseExtract[] => {
    const c = _.concat<SelectedCourseExtract>(data.tree1?.unavailableClasses, data.tree2?.unavailableClasses, data.tree3?.unavailableClasses);
    return _.filter<SelectedCourseExtract>(c, _.identity);
};

export type AllowedSectionModes = {[key in SectionMode]: boolean};

// the filters argument is to allow compatibility with search results when student is looking for a specific mode(s)
export const getAllowedSectionModes = (filters: ClassSearchCriteriaValues, encounteredStages: StageData[]): AllowedSectionModes => {
    // First instantiate based on search filters
    const results: AllowedSectionModes = {
        OA: !(filters.instructionMode === 'P' || filters.instructionMode === 'OS' || filters.instructionMode === 'S'),
        OS: !(filters.instructionMode === 'P' || filters.instructionMode === 'OA'),
        P: !(filters.instructionMode === 'O' || filters.instructionMode === 'OA' || filters.instructionMode === 'OS'),
    };
    // Then check r4 stages that cause unavailability across all courses
    encounteredStages.forEach(stage => {
        if (stage.id === 't1r4') {
            // No asynchronous sections for ALL courses
            results.OA = false;
        } else if (stage.id === 't2r4') {
            // No online synchronous sections for ALL courses
            results.OS = false;
        } else if (stage.id === 't3r4') {
            // No on campus sections for ALL courses
            results.P = false;
        }
    });

    return results;
};

export type SimulatedResults = {
    sectionCount: number,
    courses: CourseGroup[],
};

// simulateCourseResults takes the raw search results and then filters out any sections based on the search filters
// as well as any unavailable sections due to encountered simulated events.
// It also handles parsing the instruction mode to render the separate synchronous choices
export const simulateSearchResults = (rawCourseResults: CourseGroup[], filters: ClassSearchCriteriaValues, data: SimulationData): SimulatedResults => {

    const encounteredStages = parseEncounteredStages(data);
    const allowedSectionModes = getAllowedSectionModes(filters, encounteredStages);
    const unavailableClasses = parseUnavailableClasses(data);

    const courses: CourseGroup[] = _.cloneDeep(rawCourseResults);
    let sectionCount = 0;
    courses.forEach(course => {

        course.classes.forEach(courseClass => {
            courseClass.sectionModeOfferings = [];

            // First apply the search filter settings
            // Remember that OA sections are their own class item
            if (courseClass.instructionMode === 'OA') {
                if (allowedSectionModes.OA) {
                    courseClass.sectionModeOfferings.push('OA');
                }

            } else {
                if (allowedSectionModes.OS) {
                    courseClass.sectionModeOfferings.push('OS');
                }
                if (allowedSectionModes.P) {
                    courseClass.sectionModeOfferings.push('P');
                }
            }

            // Next loop through all unavailableClasses and exclude section mode for the EXACT class id
            // This applies to ALL trees
            unavailableClasses.forEach(unavailableCourse => {
                unavailableCourse.selectedSections.forEach(unavailableSection => {
                    if (unavailableCourse.courseId === course.courseId &&
                        unavailableSection.classId === courseClass.classId
                    ) {
                        _.remove(courseClass.sectionModeOfferings!, m => m === unavailableCourse.sectionMode);
                    }
                });


            });

            sectionCount += courseClass.sectionModeOfferings.length;
        });

        // Then take only encountered stages for the current tree and exclude any related sections
        // We ignore any r4 stages as those were handled with the search filter settings due to their global effects

        // Listing all the various stages and their side effects here.
        // However important to note that only stages in the same tree will take effect
        encounteredStages.forEach(stage => {

            if (stage.unavailableClass.courseId === course.courseId) {
                switch (stage.id) {
                    case 't1r1':
                        // no asynchronous sections available for this course
                        course.classes.forEach(courseClass => {
                            sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'OA').length;
                        });
                        break;
                    case 't1r2':
                        // no sections during available time
                        course.classes.forEach(courseClass => {
                            if (isClassDuringAvailableTimes(courseClass, data.availableTimes)) {
                                sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'OS').length;
                                sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'P').length;
                                console.warn('removed sections due to available times. courseClass.sectionModeOfferings: ', courseClass.sectionModeOfferings);
                            }
                        });
                        break;
                    case 't1r3':
                        // no sections period
                        course.classes.forEach(courseClass => {
                            courseClass.sectionModeOfferings = [];
                        });
                        break;
                    case 't1r4':
                        // already handled
                        break;
                    case 't2r1':
                        // no online synchronous sections
                        course.classes.forEach(courseClass => {
                            sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'OS').length;
                        });
                        break;
                    case 't2r2':
                        // no asynchronous sections
                        course.classes.forEach(courseClass => {
                            sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'OA').length;
                        });
                        break;
                    case 't2r3':
                        // no on campus sections
                        course.classes.forEach(courseClass => {
                            sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'P').length;
                        });
                        break;
                    case 't2r4':
                        // already handled
                        break;
                    case 't3r1':
                        // no on campus sections
                        course.classes.forEach(courseClass => {
                            sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'P').length;
                        });
                        break;
                    case 't3r2':
                        // no asynchronous sections
                        course.classes.forEach(courseClass => {
                            sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'OA').length;
                        });
                        break;
                    case 't3r3':
                        // no online synchronous sections
                        course.classes.forEach(courseClass => {
                            sectionCount -= _.remove(courseClass.sectionModeOfferings!, m => m === 'OS').length;
                        });
                        break;
                    case 't3r4':
                        // already handled
                        break;
                }
            }
        });

        // Remove the actual class entry if there are no section mode offerings
        _.remove(course.classes, o => !o.sectionModeOfferings?.length);
    });

    // Finally, remove all courses without any remaining classes
    _.remove(courses, o => !o.classes.length);

    return { courses, sectionCount };
};

export const isInProgress = (simResults: SimulationResults): boolean => {
    return !!(simResults.data?.startedTs && simResults.data?.currentStage);
};

