import {gql} from '@apollo/client';
import graphClient from '@/api/graph';
import {ScribedCourse} from '@/degrees/rules';

const SEARCH_COURSE_REQUIREMENTS = gql`
query searchCourseRequirements($filter:custom_crse_offer_view_bool_exp){
  items: custom_crse_offer_view(where:$filter) {
    courseId:crse_id,
    institution,
    subject,
    courseNumber:catalog_nbr,
    requirementGroupDetails: requirement_group_details(
      	order_by: {rq_grp_line_nbr: asc}
     ){
        courseId:crse_id,
      	ssrDescr:ssr_descr80,
    }
  }
}
`;

export type SearchCourseRequirements = {
    courseId: string;
    institution: string;
    subject: string;
    courseNumber: string;
    requirementGroupDetails: Array<{
        courseId: string;
        ssrDescr: string;
    }>
};


const convertScribedCourseListToHasuraQuery = (scribedList: Array<ScribedCourse>): any[] => {
    const matchConditions: any[] = [];

    scribedList.forEach(scribed => {
        const [ subject, courseNumber, expression ] = scribed;

        if (subject === '@' || courseNumber === '@') {
            return;
        }

        const condition = {
            _and: [
                { subject: { _eq: subject } },
                { catalog_nbr: { _eq: courseNumber } }
            ]
        };

        matchConditions.push(condition);
    });

    return matchConditions;
};

export const msearchCourseRequirements = async (
    institution: string,
    scribedCourseList: Array<ScribedCourse>
): Promise<any | null> => {


    const matchConditions = convertScribedCourseListToHasuraQuery(scribedCourseList);


    const results = await graphClient.query({
        query: SEARCH_COURSE_REQUIREMENTS,
        variables: {
            filter: {
                institution: {_eq: institution},
                _or: matchConditions
            }
        }
    });

    if (results.data.items.length) {
        const courseRequirementsResponse: SearchCourseRequirements = results.data.items[0];
        return courseRequirementsResponse;
    } else {
        console.warn(`searchCourseRequirements: No results found for scribedCourseList: `, scribedCourseList);
    }
    return null;
};
