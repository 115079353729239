import http from '@/api/http';
import { AccountRole } from './types';

export type GetAuthenticatedUserResponse = {
    studentId: string;
    role: AccountRole;
};
export default async (): Promise<GetAuthenticatedUserResponse> => {
    const { data } = await http.get('/me');
    return data;
};
