import { RuleGroupRequirement, SubGroup, SubGroupRuleEntry } from '@/degrees/rules';
import {StudentInfo} from '@/api/types';
import _ from 'lodash';
import {
    AuditedRuleEntry,
    AuditedSubGroup,
    AuditStatus,
    RuleEntryAuditor
} from '@/degrees/audit/types';

async function auditSubgroup(subgroup: SubGroup, studentInfo: StudentInfo, auditRuleEntry: RuleEntryAuditor): Promise<AuditedSubGroup> {

    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        requiredCredits: 0,
        requiredClasses: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,
    };
    const auditedEntries: SubGroupRuleEntry[] = [];

    for (const entry of subgroup) {

        const audited = await auditRuleEntry(entry, studentInfo);
        if (audited.requiredClasses || audited.requiredCredits) {
            auditStatus.requiredClasses += audited.requiredClasses;
            auditStatus.requiredCredits += audited.requiredCredits;

            auditStatus.completedClasses += audited.completedClasses;
            auditStatus.completedCredits += audited.completedCredits;

            auditStatus.requiredClassCreditRules = auditStatus.requiredClassCreditRules.concat(audited.requiredClassCreditRules);

            if ('conditional' in audited) {
                audited.conditional.auditedBranch?.forEach((auditedBranchEntry) => {
                    auditedEntries.push(auditedBranchEntry);
                });

            } else {
                auditedEntries.push(audited as SubGroupRuleEntry);
            }

            if (!audited.completed) {
                auditStatus.completed = false;
            }
        }

    }
    return {
        rules: auditedEntries,
        ...auditStatus,
    };
}

export default async function auditGroupRule(rule: RuleGroupRequirement, studentInfo: StudentInfo, auditRuleEntry: RuleEntryAuditor): Promise<AuditedRuleEntry> {


    const subgroupEntries: SubGroup[] = rule.group_list;

    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        requiredClasses: 0,
        requiredCredits: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,
    };

    // const auditedSubgroups: Array<{
    //     rules: SubGroupRuleEntry[]
    // }> = [];

    const auditedSubgroups: AuditedSubGroup[] = [];

    for (let i = 0; i < subgroupEntries.length; i++) {

        const audited = await auditSubgroup(subgroupEntries[i], studentInfo, auditRuleEntry);

        if (audited.requiredClasses || audited.requiredCredits) {
            auditedSubgroups.push(audited);
        }
    }

    const completedSubgroups = auditedSubgroups.filter((audited: AuditedSubGroup) => {
        return audited.completed;
    });

    // Minimum number of groups required would be specified by the group rule, but if we're filtering out non-course-related subgroups then
    // that number could fall below what is specified.
    const minGroupsNeeded: number = _.min([ auditedSubgroups.length, _.toNumber(rule.number) ]) as number;



    const sortedSubGroups = _.orderBy(auditedSubgroups, o => {
        // just give each class an average weight of 3 credits and then sort them by least to most
        return (o.requiredClasses * 3) + o.requiredCredits;
    }, [ 'asc' ]);

    const minRequiredSubGroups = sortedSubGroups.slice(0, minGroupsNeeded);

    auditStatus.requiredClasses = _.sumBy(minRequiredSubGroups, 'requiredClasses');
    auditStatus.requiredCredits = _.sumBy(minRequiredSubGroups, 'requiredCredits');


    auditStatus.completedClasses = _.clamp(_.sumBy(completedSubgroups, 'completedClasses'), 0, auditStatus.requiredClasses);
    auditStatus.completedCredits = _.clamp(_.sumBy(completedSubgroups, 'completedCredits') || 0, 0, auditStatus.completedCredits);


    if (minGroupsNeeded && completedSubgroups.length < minGroupsNeeded) {
        auditStatus.completed = false;
        auditStatus.message = 'One or more groups not met.';
    }

    auditStatus.requiredClassCreditRules = _.flatten(minRequiredSubGroups.map(o => o.requiredClassCreditRules));
    return {
        group_requirement: {
            ...rule,
            auditedSubGroups: auditedSubgroups,
            auditedMinEntries: _.flatten(minRequiredSubGroups.map(o => o.rules)),
        },
        ...auditStatus
    };
}
