import http from '@/api/http';
import { CourseExtract, getCoursesByIds } from '@/api/graphql/queries/getCourses';
import transferError from '@/utils/transferError';

export default async (courseIds: string[], destinationInstitution: string): Promise<CourseExtract[]> => {
    if (!courseIds.length) {
        return [];
    }

    const endpoint = '/transfer-rules';
    const { data } = await http.post(endpoint, { courseIds, destinationInstitution }, {
        timeout: transferError.lastEncountered ? 500 : 10000,
    });
    console.log('getTransferCourses sending courseIds: ', courseIds);
    console.log('data: ', data);

    const transferResult: {sending: string, receiving: string[]}[] = data.transferedCourses;

    const courseExtracts = await getCoursesByIds(transferResult.flatMap(o => o.receiving));

    return courseExtracts.map(course => ({
        ...course,
        sendingCourseId: transferResult.find((o) => o.receiving.includes(course.courseId))?.sending || ''
    }));
};
