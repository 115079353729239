import axios, { AxiosError } from 'axios';
import config from '@/config';
import store from '@/store';

const http = axios.create({
    baseURL: config.apiUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

http.interceptors.request.use(config => {
    const { user } = store.getState();

    // Don't attempt to attach an Authorization header to authentication routes.
    [ '/signup', '/login', '/student-login' ].forEach(route => {
        if (config.url?.endsWith(route)) {
            return config;
        }
    });

    if (config.method !== 'OPTIONS') {
        config.headers!.Authorization = `Bearer ${user.token}`;
    }

    return config;
});

// If this is a 401 error response from the API go ahead and sign the user
// out since they will need to sign back in to continue using the provider
// app.
http.interceptors.response.use(response => response, error => {
    const actions = store.getActions();

    if (error.response?.status === 401) {
        actions.logout();
    }

    return Promise.reject(parseErrorFromResponse(error));
});

export const isAxiosError = (error: any): error is AxiosError<Record<any, any>> => {
    return error &&
        Object.prototype.hasOwnProperty.call(error, 'request') &&
        Object.prototype.hasOwnProperty.call(error, 'response');
};

export const parseErrorFromResponse = (error: string | Error | AxiosError): string => {
    if (typeof error === 'string') {
        return error;
    }

    if (isAxiosError(error)) {

        let message = error.response?.data?.msg;

        // Yup validation objects return with a different format than our normal
        // error responses.
        if (error.response?.status === 422 && error.response.data?.errors?.name) {
            message = error.response.data?.errors?.message || message;
        }

        return message || error.message;
    }

    return error.message;
};

export default http;
