import {
    AcademicPlanInfo,
    AcademicProgramInfo,
    AcademicSubplanInfo,
    CourseSubjectAndNumber,
    StudentEnrollment,
    StudentInfo
} from '@/api/types';
import _ from 'lodash';
import {
    ClassOrCredit,
    CourseList,
    RuleClassCredit,
    RuleEntry,
    RuleLabel,
    RuleType,
    ScribedCourse
} from '@/degrees/rules';
import {BlockType} from '@/degrees/common';
import {
    parseCourseSubjectAndNumber,
    parseDegreeProgram,
    parseInstitutionFromAcademicPlan,
    parseInstitutionFromStudentInfo, parseLatestAcademicProgramFromStudentInfo,
    parseLatestDeclaredPlanFromStudentInfo, parseLatestDeclaredSubplanFromStudentInfo,
    parseMajorFromStudentInfo
} from '@/api/transformers';
import {DegreeInfo, StudentInfoWithDegree} from '@/degrees/audit/types';
import {RequirementBlock} from '@/degrees/types';
import {termMappings} from '@/constants/terms';

export function compareWithAttribute(expression: string, courseSubjectAndNumber: CourseSubjectAndNumber): boolean {
    // TODO: most likely need to QA to know if this actually works
    const cleaned = expression.replace(/\(with/i, '').replace(/\)/, '');

    return false;
}


function parseCourseNumber(courseNumber: string) {
    const match = courseNumber.match(/^([A-Za-z]*)(\d+)([A-Za-z]*)$/);
    if (!match) throw new Error(`Invalid course number format: ${courseNumber}`);

    const [ _, prefix, number, suffix ] = match;
    return {
        prefix, // Optional letters before the number (e.g., IM)
        number,
        suffix // Optional letters after the number (e.g., H, L)
    };
}

export function isRange(scribeCourseNumber: string): boolean {
    return scribeCourseNumber.indexOf(':') !== -1;
}

export function isPartialWildcard(courseNumber: string) {
    return courseNumber.indexOf('@') !== -1;
}

export function comparePartialWildcard(requiredCourseNumber: string, actualCourseNumber: string): boolean {
    for (let i = 0; i < requiredCourseNumber.length; i++) {

        const requiredPos = requiredCourseNumber[i];
        const actualPos = actualCourseNumber[i];

        if (requiredPos === '@') continue;

        if (requiredPos !== actualPos) return false;
    }
    return true;
}

function scribedComparator(rawScribed: ScribedCourse, courseSubjectAndNumber: CourseSubjectAndNumber): boolean {
    const [ requiredSubject, requiredCourseNumber, expression ] = rawScribed;

    if (expression) {
        const isQualifyingAttribute = compareWithAttribute(expression, courseSubjectAndNumber);
        if (!isQualifyingAttribute) return false;
    }

    if (requiredSubject === '@' && requiredCourseNumber === '@') {
        // console.log('wildcard anything, returning true');
        return true;
    }

    if (requiredSubject === '@') {
        return requiredCourseNumber === courseSubjectAndNumber.courseNumber;
    }

    if (requiredCourseNumber === '@') {
        return requiredSubject === courseSubjectAndNumber.subject;
    }

    if (requiredSubject === courseSubjectAndNumber.subject) {
        if (isPartialWildcard(requiredCourseNumber)) {
            return comparePartialWildcard(requiredCourseNumber, courseSubjectAndNumber.courseNumber);
        }



        if (isRange(requiredCourseNumber)) {
            const [ min, max ] = requiredCourseNumber.split(':');

            const { number: minNum } = parseCourseNumber(min);
            const lowerBound = parseInt(minNum.replace('/@/g', '0'));

            const { number: maxNum } = parseCourseNumber(max);
            const upperBound = parseInt(maxNum.replace('/@/g', '9'));

            const actualCourseNumber = parseInt(courseSubjectAndNumber.courseNumber);
            return actualCourseNumber >= lowerBound && actualCourseNumber <= upperBound;
        }






        return requiredCourseNumber === courseSubjectAndNumber.courseNumber;
    }

    return false;
}

export function compareQualifyingConstraint(rawScribed: ScribedCourse, studentEnrollment: StudentEnrollment): boolean {
    const scribedCourse = rawScribed;

    const compare: CourseSubjectAndNumber[] = studentEnrollment.transferredAs?.length ? studentEnrollment.transferredAs : [ studentEnrollment.class ];

    for (let i = 0; i < compare.length; i++) {
        const selectedClass = compare[i];
        const isQualifying = scribedComparator(scribedCourse, selectedClass);
        if (isQualifying) return true;
    }

    return false;
}

export function getQualifyingEnrollments(courseList: Pick<CourseList, 'except_courses' | 'scribed_courses' | 'list_type'>, enrollments: StudentEnrollment[]): StudentEnrollment[] {
    const results: StudentEnrollment[] = [];

    const { except_courses, scribed_courses, list_type} = courseList;

    const qualifyingEnrollments: StudentEnrollment[] = [];


    const qualifiedMap: Record<string, StudentEnrollment> = {};

    _.forEach(enrollments, (enrollment: StudentEnrollment) => {
        _.forEach(scribed_courses, (scribed_list: ScribedCourse[]) => {

            let matched = false;

            _.forEach(scribed_list, (scribedCourse: ScribedCourse) => {
                const isQualifying = compareQualifyingConstraint(scribedCourse, enrollment);
                if (isQualifying) {
                    const key = parseCourseSubjectAndNumber(enrollment.transferredAs?.length ? enrollment.transferredAs[0] : enrollment.class);

                    if (!qualifiedMap[key]) {
                        qualifyingEnrollments.push(enrollment);
                        qualifiedMap[key] = enrollment;

                        matched = true;
                    }
                    // return false;
                }
            });

            if (matched) {
                // EDIT: may not necessarily want to exit early because this would likely break wildcard courses such as "9 credits in MAT @"
                // which would imply we want to check the course against multiple enrollments
                // exit out and continue to next enrollment
                // return false;
            }
        });
    });


    _.forEach(except_courses, exclude => {
        _.remove(qualifyingEnrollments, enrollment => {
            const found = compareQualifyingConstraint(exclude, enrollment);
            return found;
        });
    });

    return qualifyingEnrollments;
}

export function parseDegreeInfo(studentInfo: StudentInfo): DegreeInfo {

    const degreeProgram = parseDegreeProgram(studentInfo);

    const degreePlan = _.find(studentInfo.academicPlans, planInfo => {
        return planInfo.studentCareerNum === degreeProgram.studentCareerNum;
    });

    if (!degreePlan) {
        console.warn('Unable to find degreePlan from degreeProgram. Fallback to finding latest declared plan');
    }


    const academicPlan: AcademicPlanInfo | null = degreePlan || parseLatestDeclaredPlanFromStudentInfo(studentInfo);

    const degreeInfo: DegreeInfo = {
        institution: degreeProgram.institution,
        degree: '',
        major: '',
        conc: '',
        latestDeclaredPlan: academicPlan
    };

    if (academicPlan) {

        const split = academicPlan.academicPlan.split('-');
        degreeInfo.degree = split.length > 1 ? (_.last(split) || '') : '';
        degreeInfo.major = split[0];
    }

    const academicSubplan: AcademicSubplanInfo | null = parseLatestDeclaredSubplanFromStudentInfo(studentInfo);
    if (academicSubplan) {
        degreeInfo.conc = academicSubplan.academicSubplan;
    }

    return degreeInfo;
}

export function formatMajorDescription(academicPlan: AcademicPlanInfo | null):string {
    if (!academicPlan?.plan?.transcriptDescription) return '';
    return `${academicPlan.plan.transcriptDescription} (${academicPlan.academicPlan})`;
}

export function formatDegreeMajor(degreeInfo: DegreeInfo): string {
    return `${degreeInfo.major}-${degreeInfo.degree}`;
}

export function parseDeclaredStudy(blockType: BlockType, studentInfo: StudentInfoWithDegree): string {
    switch (blockType) {
        case "MAJOR":
            return formatDegreeMajor(studentInfo);
        case "CONC":
            return studentInfo.conc;
        // case "OTHER":
        //     return;
        case "MINOR":
            return studentInfo.conc;
        case "DEGREE":
            return studentInfo.degree;
        default:
            throw new Error('unhandled declaredStudy ' + blockType);
    }
}

export function getRuleType(ruleEntry: RuleEntry): keyof RuleEntry {
    const ruleTypes: RuleType[] = [ 'class_credit', 'subset', 'conditional', 'remark', 'group_requirement', 'remark_str', 'block', 'copy_rules', 'blocktype', 'noncourse', 'course_list_rule', 'rule_complete' ];
    for (const ruleType of ruleTypes) {
        if (ruleType in ruleEntry) {
            return ruleType as keyof RuleEntry;
        }
    }
    throw new Error('Invalid RuleEntry: no known rule type found');
}

export function formatRuleEntryLabel(entry: RuleEntry): string {
    const ruleType = getRuleType(entry);
    const rule = (entry as any)[ruleType];

    let labelStr = '';

    if (rule.label) {
        if (typeof rule.label === 'string') {
            labelStr = rule.label;
        }

        if (rule.label.label_str) {
            labelStr = rule.label.label_str;
        }
    }

    return `${labelStr}`;
}

export function formatBlockExtract(block: RequirementBlock): string {
    return `${block.blockType}: ${block.blockValue}`;
}

export function formatScribedCourse(scribed: ScribedCourse): string {
    return scribed.join(' ').replace(/__hidden__/g, '').trim();
}

export function formatCourseList(courseList: CourseList): string {

    const exclude = courseList.except_courses.map(scribed => {
        return formatScribedCourse(scribed);
    }).join(', ');

    const courses = courseList.scribed_courses.map((courses, index) => {

        const courseSet = courses.map(scribed => {
            const courseStr = formatScribedCourse(scribed);
            return courseStr;
        }).join(', ');

        return `${courseSet}`;
    }).join('\n');

    let message = `${courses}`;
    if (exclude) {
        message += `\nExclude: ${exclude}`;
    }
    return message;
}

export function hasPriorDegrees(studentInfo: StudentInfo): boolean {
    return !!getPriorDegrees(studentInfo);
}

export function getPriorDegrees(studentInfo: StudentInfo): AcademicPlanInfo[] {
    return studentInfo.academicPlans.filter(plan => plan.completionTerm);
}

export function formatNumberRange(min: any, max: any): string {
    if (min === max) {
        return min;
    } else if (min && max && min !== max) {
        return `${min}:${max}`;
    } else if (min) {
        return min;
    } else {
        return max;
    }
}

export function formatCompletedDegree(plan: AcademicPlanInfo): string {
    return `${plan.academicPlan} (Completed: ${(termMappings[plan.completionTerm] || '').replace(/term/i, '').trim()})`;
}

export function parseAuditedClassCreditRule(rule: RuleClassCredit): string {
    const messages: string[] = [];

    if (rule.mingpa) {
        messages.push(`Minimum GPA: ${rule.mingpa.number}`);
    }
    if (rule.mingrade) {
        messages.push(`Minimum Grade: ${rule.mingrade.number}`);
    }
    if (rule.minclass) {
        const s = `Min Class: ${rule.minclass.number}\n\tCourse List:\t\n${formatCourseList(rule.minclass.course_list)}`;
        messages.push(s);
    }
    if (rule.mincredit) {
        const s = `Min Credit: ${rule.mincredit.number}\n\tCourse List:\t\n${formatCourseList(rule.mincredit.course_list)}`;
        messages.push(s);
    }
    if (rule.min_credits && rule.max_credits) {
        messages.push(`CREDITS: ${formatNumberRange(rule.min_credits, rule.max_credits)}`);
    }
    if (rule.min_classes && rule.max_classes) {
        messages.push(`CLASSES: ${formatNumberRange(rule.min_classes, rule.max_classes)}`);
    }
    if (rule.course_list) {
        messages.push(`\t IN\t${formatCourseList(rule.course_list)}`);
    }
    const s = messages.join('\n');
    return s;
}

