import React, {useMemo, useState} from 'react';
import {
    formatGPA,
} from '@/api/transformers';
import tw from 'twin.macro';
import { Spinner } from '../common';
import EnrollmentHistoryTable from '@/components/enrollment-history/EnrollmentHistoryTable';
import RuleStatus from '@/components/degree/RuleStatus';
import {
    BaseButtonStyle,
} from '@/components/dashboard/styled';
import {
    AuditedClassCreditRule,
    AuditedRequirementBlock,
    AuditedRule,
    AuditedRuleEntry,
    StudentInfoWithDegree
} from '@/degrees/audit/types';
import {
    formatBlockExtract,
    formatCourseList, formatMajorDescription, formatNumberRange,
    formatRuleEntryLabel,
    getRuleType, parseAuditedClassCreditRule,
} from '@/degrees/audit/utils';
import {
    RuleConditional,
    RuleGroupRequirement,
    RuleSubset,
    SubGroup, SubGroupRuleEntry,
    SubsetRuleEntry
} from '@/degrees/rules';
import {
    AuditedBlockStyle,
    AuditedRuleEntryStyle,
    DegreeRequirementsStyle, EnrollmentHistoryContainerStyle, PageContainerStyle,
    StudentFormStyle
} from '@/components/degree/styled';
import {InstitutionName} from '@/constants/institutions';
import {auditStudent} from '@/components/degree/auditStudent';
import VersionInfo from '@/components/VersionInfo';
import _ from 'lodash';
import {msearchCourseRequirements} from '@/api/graphql/queries/searchCourseRequirements';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {CourseComponentName} from '@/constants/courseComponents';

let defaultStudentId = '';
// student w/ 2nd degree
// defaultStudentId = '923317041';

// defaultStudentId = '14121086';

// transfer student
// defaultStudentId = '23977875';

const dummyInfo = {
    studentId: '923317041',
    degree: 'BS',
    institution: 'BKL01',
    major: 'COMPSC',
    conc: 'N/A',
    cumulativeGpa: 2.72,
    totalUnits: 106,
    latestDeclaredPlan: {
        "__typename": "ps_acad_plan",
        "effdt": "2021-03-04",
        "academicPlan": "COMPSC-BS",
        "declaredDate": "2020-12-05",
        "reqTerm": "1219",
        "completionTerm": "",
        "plan": {
            "__typename": "ps_acad_plan_tbl",
            "transcriptDescription": "Computer and Information Science",
            "institution": "BKL01"
        }
    }
} as any;

const AVG_CLASS_CREDIT = 3;

export default () => {

    const [ showRemainingClassCreditRules, setShowRemainingClassCreditRules ] = useState(false);
    const [ error, setError ] = useState<string>('');
    const [ studentId, setStudentId ] = useState<string>(defaultStudentId);
    const [ studentInfo, setStudentInfo ] = useState<StudentInfoWithDegree | null>();

    const [ loading, setLoading ] = useState<boolean>(false);

    const [ auditedBlock, setAuditedBlock ] = useState<AuditedRequirementBlock | null>(null);
    const [ auditErrors, setAuditErrors ] = useState<string[]>([]);


    const missingClassCreditRules = useMemo(() => {
        if (auditedBlock) {
            return auditedBlock.requiredClassCreditRules.filter(o => !o.completed);
        } else {
            return [];
        }
    }, [ auditedBlock ]);


    const onClearStudent = () => {
        setStudentInfo(null);
        setAuditedBlock(null);
    };

    const onSubmitStudentId = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        setStudentInfo(null);
        setStudentId('');
        setError('');

        auditStudent(studentId)
            .then((results) => {
                setStudentInfo(results.studentInfo);
                setAuditedBlock(results.auditedBlock);
                setAuditErrors(_.cloneDeep(results.errors));
            })
            .catch(err => {
                console.error(err);
                setError(err.toString());
            })
            .then(() => setLoading(false));
    };

    function renderSubsetRule(entry: AuditedRuleEntry<{subset: RuleSubset}>) {
        return (
            <div>
                <p>Requirements:</p>
                <div css={[ tw`mx-4` ]}>
                    {entry.subset.requirements.map((req: AuditedRuleEntry<SubsetRuleEntry>, index) => {
                        return (
                            <div key={index}>
                                {renderAuditedRuleEntry(req)}
                            </div>

                        );
                    })}
                </div>
            </div>
        );
    }

    function renderConditionalRuleAudited(entry: AuditedRuleEntry<{conditional: RuleConditional}>) {
        const rule = entry.conditional;
        if (rule.auditedBranch) {
            return (
                <div css={[ tw`mx-4` ]}>
                    {rule.auditedBranch.map((entry, index) => {
                        return (
                            <div key={index}>
                                {renderAuditedRuleEntry(entry as AuditedRuleEntry)}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            throw new Error('RuleConditional missing audited branch!');
        }
    }


    // function renderConditionalRule(entry: AuditedRuleEntry<{conditional: RuleConditional}>) {
    //     const rule = entry.conditional;
    //
    //     const branch = eval(entry.satisfiedConditionStr) ? rule.if_true : (rule.if_false || []);
    //     console.log('typeof entry.satisfiedConditionStr: ', typeof entry.satisfiedConditionStr);
    //
    //     console.log('rule.if_false: ', rule.if_false);
    //     console.log('renderConditionalRule branch: ', branch);
    //
    //     return (
    //       <div>
    //           <p>IF {rule.condition_str}: {(entry.satisfiedConditionStr!).toString()}</p>
    //
    //           {
    //               !entry.satisfiedConditionStr && rule.if_false?.length ?
    //                   <p>ELSE</p>
    //                   : null
    //           }
    //
    //           <div css={[ tw`mx-4` ]}>
    //               {branch.map((entry, index) => {
    //                   return (
    //                       <div key={index}>
    //                           {renderAuditedRuleEntry(entry as AuditedRuleEntry)}
    //                       </div>
    //                   );
    //               })}
    //           </div>
    //       </div>
    //     );
    // }

    function renderGroupRule(entry: AuditedRuleEntry<{group_requirement: RuleGroupRequirement}>) {
        const rule = entry.group_requirement;
        return (
            <div>
                {rule.auditedMinEntries.map((o, index) => {
                    return (
                        <div key={index}>
                            {renderAuditedRuleEntry(o as AuditedRuleEntry)}
                        </div>
                    );
                })}

                {/* <p>{rule.number} Groups in</p> */}

                {/* {rule.auditedSubGroups.map((subgroup: { */}
                {/*     rules: SubGroupRuleEntry[] */}
                {/* }, index: number) => { */}
                {/*     return ( */}
                {/*         <div key={index}> */}
                {/*             <p>Group #{index + 1}</p> */}
                {/*             <div css={[ tw`mx-8` ]}> */}
                {/*                 {subgroup.rules.map((entry, index) => { */}
                {/*                     return ( */}
                {/*                         <div key={index}> */}
                {/*                             {renderAuditedRuleEntry(entry as AuditedRuleEntry)} */}
                {/*                         </div> */}
                {/*                     ); */}
                {/*                 })} */}
                {/*             </div> */}

                {/*         </div> */}
                {/*     ); */}

                {/* })} */}
            </div>
        );
    }

    function renderEntryDetails(entry: AuditedRuleEntry) {
        if (entry.ignored) return null;

        if ('class_credit' in entry) {
            return renderClassCreditEntry(entry);
        }
        if ('subset' in entry) {
            return renderSubsetRule(entry);
        }
        if ('conditional' in entry) {
            return renderConditionalRuleAudited(entry);
        }
        if ('group_requirement' in entry) {
            return renderGroupRule(entry);
        }
        if ('remark' in entry) {
            return <p>{entry.remark}</p>;
        }
        if ('remark_str' in entry) {
            return <p>{entry.remark_str}</p>;
        }
        if ('block' in entry) {
            if (!entry.block.populatedBlock) {
                console.error('block entry has no populated block. entry: ', entry);
                throw new Error('no populated block');
            }
            return renderAuditedBlock(entry.block.populatedBlock!);
        }
        if ('copy_rules' in entry) {
            // console.log('copy rules entry: ', entry);
            return renderAuditedBlock(entry.copy_rules.populatedBlock!);
            // throw new Error('   unhandled copy_rules');
        }
        if ('blocktype' in entry) {
            return renderAuditedBlock(entry.blocktype.populatedBlock!);
        }
        if ('noncourse' in entry) {
            return (
                <p>{entry.noncourse.expression}</p>
            );
        }
        if ('proxy_advice' in entry) {
            return (
                <p>{entry.proxy_advice.advice_str}</p>
            );
        }
        if ('course_list_rule' in entry) {
            return (
                <div>
                    <p>minarea: {entry.course_list_rule.minarea}</p>
                    <p>Courses: {formatCourseList(entry.course_list_rule.course_list)}</p>
                </div>
            );
        }
        if ('rule_complete' in entry) {
            return (
                <p>Rule Complete</p>
            );
        }
    }


    function renderClassCreditEntry(entry: AuditedRuleEntry) {
        const rule = (entry as any).class_credit;

        const msg = parseAuditedClassCreditRule(rule);
        return <p>{msg}</p>;
    }

    function renderAuditedStatus(entry: AuditedRuleEntry) {
        return (
            <div className={'audited-status-container'} css={[ tw`` ]}>
                {
                    entry.requiredClasses ?
                        <div>
                            <p>Completed {entry.completedClasses} of {entry.requiredClasses} classes</p>
                        </div>
                        : null
                }
                {
                    entry.requiredCredits ?
                        <div>
                            <p>Completed {entry.completedCredits} of {entry.requiredCredits} credits</p>
                        </div>
                        : null
                }
            </div>
        );
    }



    function renderAuditedClassCreditRule(auditedRule: AuditedClassCreditRule) {

        msearchCourseRequirements(auditedBlock!.institution, auditedRule.rule.course_list.scribed_courses[0]);
    }


    function renderAuditedRuleEntry(entry: AuditedRuleEntry, isRoot: boolean = false) {


        return (
            <AuditedRuleEntryStyle isRoot={isRoot}>
                {renderAuditedStatus(entry)}

                <div>
                    <span css={[ tw`font-normal text-xs uppercase text-gray-500` ]}>Rule - {getRuleType(entry)}</span>
                    <p>{formatRuleEntryLabel(entry)}</p>
                </div>
                <RuleStatus entry={entry}/>
                {/* <p css={[ tw`underline font-medium` ]}>{formatRuleEntryLabel(entry)}</p> */}

                <div css={[ tw`mt-3` ]}>
                    <div css={[ tw`ml-4` ]}>
                        {
                            renderEntryDetails(entry)
                        }
                    </div>
                </div>
            </AuditedRuleEntryStyle>
        );
    }

    function renderAuditedBlock(block: AuditedRequirementBlock) {
        return (
            <AuditedBlockStyle>
                <div>
                    {/* <span css={[tw`italic`]}>Block - {formatBlockExtract(block)}</span> */}
                    <p css={[ tw`font-medium text-xl` ]}>{block.title}</p>
                </div>
                {/* <p css={[ tw`underline font-medium` ]}>{block.title}</p> */}

                <div css={[ ]}>
                    <p css={[ tw`font-light text-xs text-gray-500` ]}>BLOCK - {formatBlockExtract(block)}</p>
                    <RuleStatus entry={block}/>
                    <div css={[ tw`mx-8` ]}>
                        {
                            block.parseTree.body_list.map((entry, i) => (
                                <div key={i}>
                                    {renderAuditedRuleEntry(entry as AuditedRuleEntry, true)}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </AuditedBlockStyle>
        );
    }

    function renderRemainingClassCreditRules() {
        return (
            <ul className={'remaining-rules-list'} css={[ tw`px-2` ]}>
                {
                    missingClassCreditRules.map((o, i) => {
                        const classCreditEntry = {
                            ...o,
                            class_credit: o.rule
                        };
                        return (
                            <li key={i}>
                                <span css={[ tw`text-sm` ]}>
                                    {parseAuditedClassCreditRule(classCreditEntry.class_credit)}
                                </span>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }

    return (
        <PageContainerStyle css={[ tw`max-w-screen-xl` ]}>

            <VersionInfo/>
            <Spinner.Overlay overlayMode="light" visible={loading} size={'large'} />

            <StudentFormStyle onSubmit={onSubmitStudentId}>
                {/* <div className={'header'}> */}
                {/*     <h3>Search</h3> */}
                {/* </div> */}

                <div css={[ tw`mt-4 mb-8` ]}>
                    {/* <label css={[ tw`mb-2 block font-medium text-xl` ]}>Search Student</label> */}
                    <div css={[ tw`flex` ]}>
                        <input placeholder={'Enter Student ID'} type={'text'} value={studentId} onChange={e => setStudentId(e.target.value)} required/>
                        <BaseButtonStyle css={[ tw`mx-4 bg-indigo-700 text-white` ]} onClick={onSubmitStudentId} disabled={!studentId}>Submit</BaseButtonStyle>
                    </div>
                </div>

                {
                    error ?
                        <div>
                            <p css={[ tw`text-red-400` ]}>{error}</p>
                        </div>
                        : null
                }


                {
                    studentInfo ?
                        <div>
                            <div className={'header'} css={[ tw`flex justify-between items-center` ]}>
                                <h3>Student Details</h3>
                                <span onClick={() => onClearStudent()} css={[ tw`cursor-pointer hover:underline text-indigo-500` ]}>Clear Student</span>
                            </div>

                            <div>
                                {
                                    auditErrors.length ?
                                        auditErrors.map(error => (
                                            <p key={error} className={'error'} css={[ tw`font-medium text-red-500 mb-4` ]}>{error}</p>
                                        ))
                                        : null
                                }
                            </div>

                            <div className={'details-container'}>

                                <div>
                                    <div className={'field'}>
                                        <label>Student ID:</label>
                                        <span>{studentInfo.studentId}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Degree:</label>
                                        <span>{studentInfo.degree ? `${studentInfo.degree} (${InstitutionName[studentInfo.institution] || studentInfo.institution})` : 'N/A'}</span>
                                    </div>
                                    <div className={'field'}>
                                        <label>Major:</label>
                                        <span>{formatMajorDescription(studentInfo.latestDeclaredPlan) || 'N/A'}</span>
                                    </div>
                                </div>

                                <div>
                                    <div className={'field'}>
                                        <label>Concentration:</label>
                                        <span>{studentInfo.conc || 'N/A'}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Cumulative GPA: </label>
                                        <span>{formatGPA(studentInfo.cumulativeGpa)}</span>
                                    </div>

                                    <div className={'field'}>
                                        <label>Total Units Earned: </label>
                                        <span>{studentInfo.totalUnits}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : null

                }


            </StudentFormStyle>

            {
                auditedBlock ?
                    <DegreeRequirementsStyle>
                        <div className={'header'}>
                            <h3>Degree Requirements</h3>
                        </div>

                        <div>
                            <div css={[ tw`my-4` ]}>
                                <span css={[ tw`text-base whitespace-pre` ]}><span css={[ tw`font-medium` ]}>Classes Required: {auditedBlock.requiredClasses}</span>  |  Completed: {auditedBlock.completedClasses}  |  Remaining: {auditedBlock.requiredClasses - auditedBlock.completedClasses}</span>
                                <div>
                                    <span css={[ tw`italic` ]}>Estimated credits from required classes: {auditedBlock.requiredClasses * AVG_CLASS_CREDIT} (assuming an average of 3 credits per course)</span>
                                </div>
                            </div>

                            <div css={[ tw`my-4` ]}>
                                <span css={[ tw`text-base whitespace-pre` ]}><span css={[ tw`font-medium` ]}>Credits Required: {auditedBlock.requiredCredits}</span>  |  Completed: {auditedBlock.completedCredits}  |  Remaining: {auditedBlock.requiredCredits - auditedBlock.completedCredits}</span>
                            </div>

                            <div css={[ tw`my-4` ]}>
                                <span css={[ tw`font-medium underline` ]}>Total Estimated Credits Required:  {auditedBlock.requiredClasses * AVG_CLASS_CREDIT + auditedBlock.requiredCredits}</span>
                            </div>

                            {
                                missingClassCreditRules.length ?
                                    <div css={[ tw`my-4 border-solid border border-gray-500` ]}>
                                        <div css={[ tw`cursor-pointer select-none flex` ]} className="" onClick={() => setShowRemainingClassCreditRules(!showRemainingClassCreditRules)}>
                                            {/* <p css={[ tw`text-link underline`]}>{showRemainingClassCreditRules ? 'Hide' : 'Show'} Remaining</p> */}
                                            <p css={[ tw`text-link underline` ]}>{showRemainingClassCreditRules ? '' : 'Show'} Remaining</p>
                                            <a css={[ tw`text-link mx-2` ]}>
                                                <FontAwesomeIcon icon={showRemainingClassCreditRules ? faAngleDown : faAngleRight } />
                                            </a>
                                        </div>

                                        <div
                                            style={{
                                                display: showRemainingClassCreditRules ? 'block' : 'none',
                                            }}
                                        >
                                            {renderRemainingClassCreditRules()}
                                        </div>

                                    </div>
                                    : null

                            }
                        </div>

                        {
                            auditedBlock ?
                                <div css={[ tw` ` ]}>
                                    <div css={[ tw` flex text-base font-normal` ]}>
                                        <p css={[ tw` mx-2 ` ]}>{auditedBlock.requirementId}</p>
                                        <p css={[ tw` mx-2 ` ]}>Period Start: {auditedBlock.periodStart}</p>
                                        <p css={[ tw` mx-2 ` ]}>Period Stop: {auditedBlock.periodStop.indexOf('9') !== 0 ? auditedBlock.periodStop : '-'}</p>
                                    </div>

                                    {renderAuditedBlock(auditedBlock)}
                                </div>
                                : null
                        }
                    </DegreeRequirementsStyle>
                    : null
            }

            {
                studentInfo && !loading ?
                    <EnrollmentHistoryContainerStyle>
                        <div className={'header'}>
                            <h3>Enrollment History</h3>
                        </div>
                        <EnrollmentHistoryTable enrollments={studentInfo.enrollments}/>
                    </EnrollmentHistoryContainerStyle>
                    :
                    null
            }



        </PageContainerStyle>
    );
};
