import {RuleBlock, RuleBlockType, RuleCopyRules, RuleEntry} from '@/degrees/rules';
import auditClassCreditRule from '@/degrees/audit/auditClassCreditRule';
import auditSubsetRule from '@/degrees/audit/auditSubsetRule';
import {AuditedRule, AuditedRuleEntry, StudentInfoWithDegree} from '@/degrees/audit/types';
import auditConditionalRule from '@/degrees/audit/auditConditionalRule';
import auditRemarkRule from '@/degrees/audit/auditRemarkRule';
import auditGroupRule from '@/degrees/audit/auditGroupRule';
import {getRequirementBlock, getRequirementBlockById} from '@/api/graphql/queries/getRequirementBlock';
import {RequirementBlock} from '@/degrees/types';
import {parseDeclaredStudy} from '@/degrees/audit/utils';
import auditBlockRule from '@/degrees/audit/auditBlockRule';
import {AuditFlagEnum} from '@/degrees/audit/constants';
import _ from 'lodash';

export default async function auditRuleEntry(
    entry: RuleEntry,
    studentInfo: StudentInfoWithDegree,

)
    : Promise<AuditedRuleEntry> {

    let auditedEntry: AuditedRuleEntry | null = null;
    const ruleEntry: RuleEntry = _.cloneDeep(entry);

    if ('class_credit' in ruleEntry) {
        auditedEntry = auditClassCreditRule(ruleEntry.class_credit, studentInfo);

    } else if ('subset' in ruleEntry) {
        auditedEntry = await auditSubsetRule(ruleEntry.subset, studentInfo, auditRuleEntry);

    } else if ('conditional' in ruleEntry) {
        auditedEntry = await auditConditionalRule(ruleEntry.conditional, studentInfo, auditRuleEntry);

    } else if ('remark' in entry || 'remark_str' in entry || 'noncourse' in entry || 'rule_complete' in entry || 'course_list_rule' in entry) {
        auditedEntry = {
            ignored: true,
            requiredClasses: 0,
            requiredCredits: 0,
            requiredClassCreditRules: [],
            completedClasses: 0,
            completedCredits: 0,
        };
    } else if ('group_requirement' in ruleEntry) {
        auditedEntry = await auditGroupRule(ruleEntry.group_requirement, studentInfo, auditRuleEntry);

    } else if ('block' in ruleEntry) {
        const rule: RuleBlock = ruleEntry.block;

        const institution = rule.institution || studentInfo.institution;

        const block: RequirementBlock = await getRequirementBlock({
            institution,
            blockType: rule.block_type,
            blockValue: rule.block_value
        });

        if (!block) {
            const context = {
                studentId: studentInfo.studentId,
                ruleType: 'block',
                institution,
                blockType: rule.block_type,
                blockValue: rule.block_value
            };
            const message = `Unable to fetch requirement block: ${JSON.stringify(context)}`;
            throw new Error(message);
        }

        const auditedBlock = await auditBlockRule(block, studentInfo);
        if (!auditedBlock) {
            console.error('ruleEntry.block: ', ruleEntry.block);
            throw new Error('auditedBlock is undefined in auditing block');
        }

        rule.populatedBlock = auditedBlock;
        auditedEntry = {
            block: rule,
            completed: auditedBlock.completed,
            message: auditedBlock.message,
            requiredClasses: auditedBlock.requiredClasses,
            requiredCredits: auditedBlock.requiredCredits,
            requiredClassCreditRules: auditedBlock.requiredClassCreditRules,
            completedClasses: auditedBlock.completedClasses,
            completedCredits: auditedBlock.completedCredits,
        };


    } else if ('copy_rules' in ruleEntry) {
        const rule: RuleCopyRules = ruleEntry.copy_rules;

        const props = {
            institution: studentInfo.institution
        };

        let block: RequirementBlock;
        if (rule.requirement_id) {
            block = await getRequirementBlockById({
                institution: studentInfo.institution,
                id: rule.requirement_id
            });
        } else {
            block = await getRequirementBlock({
                institution: studentInfo.institution,
                blockType: rule.block_type,
                blockValue: rule.block_value
            });
        }

        if (!block) {
            const context = {
                studentId: studentInfo.studentId,
                ruleType: 'copy_rules',
                requirement_id: rule.requirement_id,
                institution: studentInfo.institution,
            };
            const message = `Unable to fetch requirement block: ${JSON.stringify(context)}`;
            throw new Error(message);
        }

        const auditedBlock = await auditBlockRule(block, studentInfo);
        if (!auditedBlock) {
            throw new Error('auditedBlock is undefined in auditing blocktype');
        }

        rule.populatedBlock = auditedBlock;
        auditedEntry = {
            copy_rules: rule,
            completed: auditedBlock.completed,
            message: auditedBlock.message,
            requiredClasses: auditedBlock.requiredClasses,
            requiredCredits: auditedBlock.requiredCredits,
            requiredClassCreditRules: auditedBlock.requiredClassCreditRules,
            completedClasses: auditedBlock.completedClasses,
            completedCredits: auditedBlock.completedCredits,
        };

    } else if ('blocktype' in ruleEntry) {
        const rule: RuleBlockType = ruleEntry.blocktype;

        let blockValue;
        try {
            blockValue = parseDeclaredStudy(rule.block_type, studentInfo);
        } catch (err) {
            console.error('auditRuleEntry err in blocktype rule: ', rule);
            throw err;
        }

        // ignoring if blocktype is minor since not sure if possible to implement
        if (!blockValue && rule.block_type === 'MINOR') {
            auditedEntry = {
                blocktype: rule,
                completed: true,
                message: '',
                ignored: true,
                requiredClasses: 0,
                requiredCredits: 0,
                requiredClassCreditRules: [],
                completedClasses: 0,
                completedCredits: 0,
            };
            return auditedEntry;
        }

        const block:RequirementBlock = await getRequirementBlock({
            institution: studentInfo.institution,
            blockType: rule.block_type,
            blockValue
        });

        if (!block) {
            const context = {
                studentId: studentInfo.studentId,
                ruleType: 'blocktype',
                institution: studentInfo.institution,
                blockType: rule.block_type,
                blockValue: blockValue
            };
            const message = `Unable to fetch requirement block: ${JSON.stringify(context)}`;
            throw new Error(message);
        }

        const auditedBlock = await auditBlockRule(block, studentInfo);
        if (!auditedBlock) {
            throw new Error('auditedBlock is undefined in auditing blocktype');
        }
        rule.populatedBlock = auditedBlock;
        auditedEntry = {
            blocktype: rule,
            completed: auditedBlock.completed,
            message: auditedBlock.message,
            requiredClasses: auditedBlock.requiredClasses,
            requiredCredits: auditedBlock.requiredCredits,
            requiredClassCreditRules: auditedBlock.requiredClassCreditRules,
            completedClasses: auditedBlock.completedClasses,
            completedCredits: auditedBlock.completedCredits,
        };

    }
    // else if ('remark' in ruleEntry) {
    //     auditedEntry = auditRemarkRule(ruleEntry.remark, studentInfo);
    // }
    // else if ('remark_str' in ruleEntry) {
    //     auditedEntry = auditRemarkRule(ruleEntry.remark_str, studentInfo);
    //
    // }
    // else if ('noncourse' in ruleEntry) {
    //     auditedEntry = {
    //         noncourse: ruleEntry.noncourse,
    //         completed: true,
    //         flags: [AuditFlagEnum.ignored],
    //     };
    //
    // } else if ('course_list_rule' in ruleEntry) {
    //     throw new Error('auditRuleEntry unhandled entry: ' + ruleEntry);
    // } else if ('rule_complete' in ruleEntry) {
    //     auditedEntry = {
    //         rule_complete: ruleEntry.rule_complete,
    //         completed: true
    //     };
    //
    // }
    else {
        throw new Error('auditRuleEntry unhandled entry: ' + ruleEntry);
    }

    if (!auditedEntry) {
        throw new Error('auditRuleEntry unhandled entry: ' + ruleEntry);
    }
    return auditedEntry;

}



