import {RuleSubset, SubsetRuleEntry} from '@/degrees/rules';
import {StudentInfo} from '@/api/types';
import {AuditedRuleEntry, AuditStatus, RuleEntryAuditor} from '@/degrees/audit/types';

export default async function auditSubsetRule(rule: RuleSubset, studentInfo: StudentInfo, auditRuleEntry: RuleEntryAuditor): Promise<AuditedRuleEntry> {

    const requirements = rule.requirements;

    const auditedReqs: SubsetRuleEntry[] = [];

    const auditStatus: AuditStatus = {
        completed: true,
        message: '',
        requiredCredits: 0,
        requiredClasses: 0,
        requiredClassCreditRules: [],
        completedClasses: 0,
        completedCredits: 0,
    };

    for (let i = 0; i < requirements.length; i++) {
        const audited = await auditRuleEntry(requirements[i], studentInfo) as AuditedRuleEntry<SubsetRuleEntry>;

        if (audited.requiredClasses || audited.requiredCredits) {
            auditStatus.requiredClasses += audited.requiredClasses;
            auditStatus.requiredCredits += audited.requiredCredits;

            auditStatus.completedClasses += audited.completedClasses;
            auditStatus.completedCredits += audited.completedCredits;

            auditStatus.requiredClassCreditRules = auditStatus.requiredClassCreditRules.concat(audited.requiredClassCreditRules);

            if ('conditional' in audited) {
                audited.conditional.auditedBranch?.forEach((auditedBranchEntry) => {
                    auditedReqs.push(auditedBranchEntry);
                });

            } else {
                auditedReqs.push(audited);
            }

            if (!audited.completed) {
                auditStatus.completed = false;
                auditStatus.message = 'One or more requirements in sub block not met.';
            }
        }
    }

    return {
        subset: {
            ...rule,
            requirements: auditedReqs
        },
        ...auditStatus
    };
}
