// searchStudentInfo is for when our ps_names / custom_names doesn't contain the relevant student.
// This seems to be likely for when trying to query for older students prior to 2020
import { gql } from '@apollo/client';
import graphClient from '@/api/graph';
import * as fragments from '@/api/graphql/fragments';
import {StudentInfo} from '@/api/types';

const SEARCH_STUDENT_INFO = gql`
query searchStudentInfo($studentId:String!) {
    academicPlans: ps_acad_plan(where:{
      emplid: {
        _eq: $studentId
      } 
    }) {
        effdt
        academicPlan: acad_plan
        declaredDate: declare_dt
        reqTerm: req_term
        completionTerm: completion_term
        plan {
            transcriptDescription: trnscr_descr
            institution
        }
    }
    
    academicSubplans: ps_acad_subplan(where:{
      emplid: {
        _eq: $studentId
      } 
    }) {
        effdt
        academicSubplan: acad_sub_plan
        academicPlan: acad_plan
        declaredDate: declare_dt
        reqTerm: req_term
    }
        
    academicPrograms: ps_acad_prog(where:{
      emplid: {
        _eq: $studentId
      } 
    }) {
        effdt
        institution
        academicProgram: acad_prog
        admitTerm: admit_term
        completionTerm: completion_term
    }
        
    enrollments: ps_stdnt_enrl(where:{
      emplid: {
        _eq: $studentId
      } 
    }
       order_by: [{strm: desc}, {institution: asc}, {class_nbr: asc}]
    ) {
        ...enrollments
    }  

        
    milestones: ps_milestones(where:{
      emplid: {
        _eq: $studentId
      } 
    }) {
            institution,
            milestoneName: milestone,
            description: descrformal,
            level
        }
        
    groups: ps_student_groups(where:{
      emplid: {
        _eq: $studentId
      } 
    }) {
        institution,
        groupName: group_name,
        description: descr
    }
        
    placements: ps_test_placements(where:{
      emplid: {
        _eq: $studentId
      } 
    }) {
        testId: test_id,
        testComponent: test_component,
        date: test_dt,
        score,
        letterScore: score_ltr
    }
}
${fragments.enrollments}
`;

export async function searchStudentInfo(studentId: string) {
    const results = await graphClient.query({
        query: SEARCH_STUDENT_INFO,
        variables: { studentId }
    });

    const data: Pick<StudentInfo,
        'studentId' |
        'academicPlans' | 'academicSubplans' | 'academicPrograms' | 'enrollments' | 'milestones' | 'groups' | 'placements'
    > = {
        studentId,
        ...results.data
    };

    console.log('results: ', results);
    console.log('data: ', data);

    return data;
}
